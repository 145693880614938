// Author: Shubham Sonkar

// Import necessary dependencies and styles
import React, { useState, useReducer, Suspense } from "react";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { useSetting } from "../hooks/Hooks";
import Skeleton from "@mui/material/Skeleton";
import CryptoJS from "crypto-js";
// import { useQuery } from "react-query";
// import { CheckUserLogin } from "../hooks/Auth/Authentication";
import CircularProgress from "@mui/material/CircularProgress";

// Define the Main component
const Main = ({ token }) => {
  // Retrieve globalData from useSetting hook
  const { globalData } = useSetting();

  // Set initial state for myglobalData
  const [myglobalData, setMyglobalData] = React.useState(globalData);
  // Styled component for DrawerHeader
  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    ...theme.mixins.toolbar,
  }));

  // State for app startup and loading
  const [appInitialised, setAppInitialised] = useState(false);
  const [loading, setLoading] = React.useState(true);

  // Function to load default screen on app startup
  function loadDefault() {
    // console.log("load default");
    if (appInitialised === false) {
      // check last active screen from local storage
      if (localStorage.getItem("currScreen") !== null) {
        // Set default screen to Login if not found
        if (
          localStorage.getItem("currScreen").length === 0 ||
          localStorage.getItem("currScreen") === ""
        ) {
          localStorage.setItem("currScreen", "Login");
        }
        let currScreen = localStorage.getItem("currScreen");
        dispatch({ event: currScreen });
      } else {
        // Set default screen to Login if no previous screen found
        dispatch({ event: "Login" });
      }
    }
  }

  // Function to encrypt and set data in local storage
  function setLocalData(key, value) {
    let CryptoJSKey = globalData.CompanyName + "@" + globalData.Version;
    var user_data = CryptoJS.AES.encrypt(value, CryptoJSKey).toString();
    localStorage.setItem(key, user_data);
  }

  // const LoginCheck = useQuery("logincheck", () => {
  //   return CheckUserLogin();
  // });

  // Mapping of event names to corresponding components
  const componentConfig = {
    // Map event names to their corresponding components
    Login: myglobalData.login.path,
    signup: myglobalData.signup.path,

    dashboard: myglobalData.pages.dashboard,
    forgotpassword: myglobalData.pages.forgotpassword,
    addloanenquiry: myglobalData.pages.addloanenquiry,
    editloanenquiry: myglobalData.pages.editloanenquiry,
    viewloanenquiry: myglobalData.pages.viewloanenquiry,
    loanenquiry: myglobalData.pages.loanenquiry,

    editpartner: myglobalData.pages.editpartner,
    viewpartner: myglobalData.pages.viewpartner,
    partner: myglobalData.pages.partner,

    stage: myglobalData.pages.dynamicfields.stage,
    loantype: myglobalData.pages.dynamicfields.loantype,
    entitytype: myglobalData.pages.dynamicfields.entitytype,

    changepassword: myglobalData.pages.changepassword,


    profile: myglobalData.pages.other.profile,
    crmsetting: myglobalData.pages.other.crmsetting,
    loader: myglobalData.pages.other.loader,
  };
  // Reducer for navigation state
  function navReducer(state, action) {
    const tempData = { ...myglobalData };
    // Handle special events and update global data accordingly
    if (action.event === "store_search_data") {
      setLocalData("store_search_data", action.data);
    } else if (action.event === "updateglobal_userdata") {
      setLocalData("updateglobal_userdata", action.data);
      tempData.user_data = action.data;
      setMyglobalData(tempData);
    } else if (action.event === "store_new_data") {
      setLocalData("store_new_data", action.data);
    } else if (action.event === "setlocaldata") {
      setLocalData(action.key, action.value);
    } else {
      // Handle regular navigation events
      const component = componentConfig[action.event];
      if (component) {
        // Update current and previous screen in local storage
        localStorage.setItem(
          "previousScreen",
          localStorage.getItem("currScreen")
        );
        setTimeout(() => {
          localStorage.setItem("currScreen", action.event);
          tempData.currScreen = component;
          setMyglobalData(tempData);
        }, 1);
      } else {
        console.log("No action");
      }
    }
  }
  // UseReducer hook for navigation
  const [state, dispatch] = useReducer(navReducer);

  React.useEffect(() => {
    // Perform initialization on app startup
    if (appInitialised === false) {
      setAppInitialised(true);
      // if (!LoginCheck.isLoading) {
      //   if (LoginCheck.data?.data == "Session found") {
      loadDefault();
      // } else {
      //   dispatch({ event: "Login" });
      // }
      // }
    }
    // Set loading to false after a short delay
    setTimeout(() => {
      setLoading(false);
    }, 10);
  }, []);

  return !loading ? (
    <>
      {appInitialised && (
        <Suspense
          callback={console.log("Loading...")}
          fallback={
            localStorage.getItem("currScreen") === "Login" ? null : localStorage.getItem("currScreen") === "signup" ? null : localStorage.getItem("currScreen") === "forgotpassword" ? null : (
              <Box sx={{ display: "flex" }}>
                <myglobalData.pages.components.leftsidebar
                  dispatch={dispatch}
                  myglobalData={myglobalData}
                />
                <DrawerHeader />
                <Box component="main" sx={{ flexGrow: 1, px: 3, py: 10 }}>
                  <Skeleton variant="rectangular" width="100%" height={30} />
                  <Skeleton
                    variant="rounded"
                    width="100%"
                    height={300}
                    sx={{ mt: 3 }}
                  />
                  <Skeleton
                    variant="rounded"
                    width="100%"
                    height={300}
                    sx={{ mt: 3 }}
                  />
                </Box>
              </Box>
            )
          }
        >
          {localStorage.getItem("currScreen") === "Login" ? (
            <myglobalData.currScreen
              dispatch={dispatch}
              myglobalData={myglobalData}
              token={token}
            />
          ) : localStorage.getItem("currScreen") === "signup" ? (
            <myglobalData.currScreen
              dispatch={dispatch}
              myglobalData={myglobalData}
              token={token}
            />
          ) : localStorage.getItem("currScreen") === "forgotpassword" ? (
            <myglobalData.currScreen
              dispatch={dispatch}
              myglobalData={myglobalData}
              token={token}
            />
          ) : localStorage.getItem("currScreen") === "loader" ? (
            <myglobalData.currScreen
              dispatch={dispatch}
              myglobalData={myglobalData}
            />
          ) : (
            <Box style={{ display: "flex" }}>
              <myglobalData.pages.components.leftsidebar
                dispatch={dispatch}
                myglobalData={myglobalData}
              />
              <DrawerHeader />
              <Box
                component="main"
                sx={{ flexGrow: 1, px: 3, py: 10, overflowX: "hidden" }}
              >
                <myglobalData.currScreen
                  dispatch={dispatch}
                  myglobalData={myglobalData}
                />
              </Box>
            </Box>
          )}
        </Suspense>
      )}
    </>
  ) : (
    // <h6>Loading...</h6>
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <CircularProgress />
    </Box>
  );
};

export default Main;
