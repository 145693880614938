import React from "react";
import dayjs from "dayjs";
import { SettingContext } from "./Context";

const MyGlobalData = ({ children }) => {
  let globalData = {
    URL: window.location.hostname,
    // API_URL: "http://localhost:3003",
    API_URL: "https://backend.fs.bprimo.com",
    // API_URL: "https://bcfsbprimo.rabsconnect.in",

    variable: {
      DateTime: dayjs().format("YYYY-MM-DD HH:mm:ss"),
    },
    CompanyName: "BPrimo",
    ClienCode: "202113",
    Version: "2.0.1",
    currScreen: "",
    login: {
      path: React.lazy(() => import("../pages/Auth/Login/Login")),
    },
    forgetpassword: {
      path: React.lazy(() =>
        import("../pages/Auth/ForgotPassword/ForgotPassword")
      ),
    },
    signup: {
      path: React.lazy(() => import("../pages/Auth/SignUp/SignUp")),
    },
    module_privilege: "",
    user_data: "",
    data_array: [],
    pages: {
      components: {
        leftsidebar: React.lazy(() =>
          import("../components/Leftsidebar/Leftsidebar")
        ),
      },
      dashboard: React.lazy(() => import("../pages/Dashboard/Dashboard")),
      forgotpassword: React.lazy(() =>
        import("../pages/Auth/ForgotPassword/ForgotPassword")
      ),      
      addloanenquiry: React.lazy(() => import("../pages/LoanEnquiry/AddLoanEnquiry")),
      editloanenquiry: React.lazy(() => import("../pages/LoanEnquiry/EditLoanEnquiry")),
      viewloanenquiry: React.lazy(() => import("../pages/LoanEnquiry/ViewLoanEnquiry")),
      loanenquiry: React.lazy(() => import("../pages/LoanEnquiry/LoanEnquiry")),
      
      editpartner: React.lazy(() => import("../pages/Partner/EditPartner")),
      viewpartner: React.lazy(() => import("../pages/Partner/ViewPartner")),
      partner: React.lazy(() => import("../pages/Partner/Partner")),

      changepassword: React.lazy(() => import("../pages/Auth/ChangePassword/ChangePassword")),
      
      dynamicfields: {
        stage: React.lazy(() => import("../pages/DynamicFields/Stage")),
        loantype: React.lazy(() => import("../pages/DynamicFields/LoanType")),
        entitytype: React.lazy(() => import("../pages/DynamicFields/EntityType")),
      },
      other: {
        loader: React.lazy(() => import("../pages/Other/Loader")),
        profile: React.lazy(() => import("../pages/Other/Profile/Profile")),
      },
    },
  };

  return (
    <SettingContext.Provider value={{ globalData }}>
      {children}
    </SettingContext.Provider>
  );
};

export default MyGlobalData;
